import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, ResponsiveContext, Heading, Text, Grid, Anchor } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ISitemapProps {
}

interface SitemapState {
}

type SitemapProps = ISitemapProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Sitemap extends React.PureComponent<SitemapProps, SitemapState> {
    constructor(props: SitemapProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['Metadata.SitemapKeywords', 'Metadata.SitemapDescription', 'Datacard.Exams_Label', 'ExternalPages.CertificationExams_Tab1',
            'ExternalPages.CertificationExams_Tab2', 'ExternalPages.CertificationExams_Tab3', 'ExternalPages.CertificationExams_Tab4',
            'ExternalPages.Overview_MoreLearning_Communities_Box1_Title', 'ExternalPages.Aruba_Airheads_Label',
            'ExternalPages.Overview_MoreLearning_Enablement_Box1_Title', 'ExternalPages.Overview_MoreLearning_Enablement_Box2_Title',
            'ExternalPages.Overview_MoreLearning_Enablement_Box3_Title', 'ExternalPages.Overview_MoreLearning_Enablement_Box4_Title',
            'ExternalPages.Overview_MoreLearning_MoreCerts_Box1_Title', 'Datacard.Learning_Center_Label'];

        this.props.setActiveMenu('help-menu');
        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.setLoading(true);
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Help_Label'], value: "" },
            { key: site.menuStrings['Datacard.Site_Map_Label'], value: "" }
        ];
        let title = site.menuStrings['Datacard.Site_Map_Label'];

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="SiteMap" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.SitemapKeywords']}
                    metadataDescription={site.localizedStrings['Metadata.SitemapDescription']} >
                    <Box gap="medium" pad={{ bottom: "small" }}>
                        <Box fill="horizontal">
                            <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                        </Box>
                        <Grid rows="auto" columns="large" fill>
                            <Box gap="medium" fill>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['Datacard.Get_Started_Label']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['Datacard.Get_Started_Label']}`} href={`${localUrl}/getstarted/partners`} />
                                        <Anchor label={`${site.menuStrings['Datacard.HPE_Learner_ID_Label']}`} href={`${localUrl}/getstarted`} />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['Datacard.Training_Label']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['ExternalPages.Menu_Search_For_Training']}`} href={`${localUrl}/TrainingCalendar`} />
                                        <Anchor label={`${site.menuStrings['Datacard.View_All_Courses_Label']}`} href={`${localUrl}/courses`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Menu_Find_Class']}`} href={`${localUrl}/LearningPartners`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Menu_Self_Directed_Learning']}`} href={`${localUrl}/hpepress`} />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['ExternalPages.Navigation_Technical']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['Datacard.View_All_Certifications_Label']}`} href={`${localUrl}/certifications`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Credentials_ViewAll_Label']}`} href={`${localUrl}/credentials`} />
                                        <Anchor label={`${site.menuStrings['Datacard.Exam_ViewAll_Label']}`} href={`${localUrl}/exams`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.TechnicalCertifications_HybridCloud_Title']}`} href={`${localUrl}/technical-certifications`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.TechnicalCertifications_Aruba_Title']}`} href={`${localUrl}/technical-certifications-networking`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.TechnicalCertifications_JoinCommunities_Title']}`} href={`${localUrl}/technical-certifications/join-communities`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Overview_GetCertified']}`} href={`${localUrl}/technical-certifications/get-certified`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Technical_KeepCurrent']}`} href={`${localUrl}/technical-certifications/keep-current`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Showcase']}`} href={`${localUrl}/technical-certifications-badges`} />
                                        <Anchor label={`${site.menuStrings['Datacard.Certification_Paths_Label']}`} target="_blank"
                                            href={`${localUrl}/documents/certificationPath/${langInfo[0] === 'ja'
                                                ? "CertificationPaths_A4.pdf"
                                                : "CertificationPaths_Letter.pdf"}`} />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['ExternalPages.Navigation_Sales']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['Datacard.View_All_Certifications_Label']}`}
                                            href={`${localUrl}/certifications?credentialView=Sales Certifications`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Overview']}`} href={`${localUrl}/sales-certifications`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Sales_Join']}`} href={`${localUrl}/hpe-sales-pro`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Showcase']}`} href={`${localUrl}/sales-certifications-badges`} />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.localizedStrings['Datacard.Exams_Label']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.localizedStrings['ExternalPages.CertificationExams_Tab1']}`} href={`${localUrl}/certification-exams`} />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.CertificationExams_Tab2']}`} href={`${localUrl}/certification-exams/preparation`} />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.CertificationExams_Tab3']}`} href={`${localUrl}/certification-exams/types`} />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.CertificationExams_Tab4']}`} href={`${localUrl}/certification-exams/vouchers`} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box gap="medium" fill>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['ExternalPages.Navigation_CommunitiesPrograms']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.localizedStrings['ExternalPages.Overview_MoreLearning_Communities_Box1_Title']}`}
                                            href="https://techpro.hpe.com/about.aspx" target="_blank" />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Aruba_Airheads_Label']}`}
                                            href="https://community.arubanetworks.com/home" target="_blank" />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box1_Title']}`}
                                            href="https://salespro.hpe.com/" target="_blank" />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box2_Title']}`}
                                            href="https://hr.ext.hpe.com/LMS-Sign-in" target="_blank" />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box3_Title']}`}
                                            href="https://education.hpe.com/ww/en/training/index.html" target="_blank" />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.Overview_MoreLearning_Enablement_Box4_Title']}`}
                                            href="https://www.arubanetworks.com/support-services/training-services/" target="_blank" />
                                        <Anchor label={`${site.localizedStrings['ExternalPages.Overview_MoreLearning_MoreCerts_Box1_Title']}`}
                                            href="https://www.mylearninghpe.com/" target="_blank" />
                                        <Anchor label={`${site.localizedStrings['Datacard.Learning_Center_Label']}`}
                                            href="http://hpe-external.sabacloud.com/" target="_blank" />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['Datacard.Resources_Label']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['Datacard.Certification_Paths_Label']}`} target='_blank'
                                            href={`${localUrl}/documents/certificationPath/${langInfo[0] === 'ja'
                                                ? "CertificationPaths_A4.pdf"
                                                : "CertificationPaths_Letter.pdf"}`} />
                                        <Anchor label={`${site.menuStrings['Datacard.HPE_Press_Books_Label']}`} href={`${localUrl}/hpepress`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Resources_Brochure']}`}
                                            href={`${localUrl}/documents/external/4AA4-2093.pdf`} target='_blank' />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_Resources_BuyVoucher']}`}
                                            href={`${localUrl}/certification-exams/vouchers`} />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['ExternalPages.Navigation_FollowUs']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_FollowUs_Forum']}`} target="_blank"
                                            href="https://community.hpe.com/t5/Partner-Certification/ct-p/PartnerCertification" />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_FollowUs_Blog']}`} target="_blank"
                                            href="https://community.hpe.com/t5/Advancing-Life-Work/bg-p/company" />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Navigation_FollowUs_LinkedIn']}`} target="_blank"
                                            href="https://www.linkedin.com/groups/1336927/" />
                                    </Box>
                                </Box>
                                <Box gap="xsmall">
                                    <Text size="large" weight="bold">{site.menuStrings['ExternalPages.Help_Label']}</Text>
                                    <Box gap="xsmall" pad={{ left: 'small' }}>
                                        <Anchor label={`${site.menuStrings['ExternalPages.Contact_Support_Label']}`} href={`${localUrl}/support`} />
                                        <Anchor label={`${site.menuStrings['ExternalPages.Contact_Security_Label']}`} href={`${localUrl}/contact-security`} />
                                        <Anchor label={`${site.menuStrings['Datacard.Assist_Kit_Label']}`} href={`${localUrl}/assistkit`} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Sitemap as any));
